export enum MIXPANEL_USER_PROPERTIES {
  USER_TYPE = 'User Type',
  DISPLAY_NAME = '$name',
  MCNUMBER = 'MC Number',
  PHONE = '$phone',
  EMAIL = '$email',
  EQUIPMENT_LIST = 'Equipment List',
  PREFERRED_LANES = 'Preferred Lanes',
  COMPANY_TYPE = 'Company Type',
  ELD_CHANNEL = 'ELD Channel',
  APP_VERSION = 'Last Login App Version',
  EXPERIMENT_NAME = 'Experiment Name',
  EXPERIMENT_VALUE = 'Experiment Value',
}

export enum MIXPANEL_KEYS {
  DATE_TIME = 'Date/Time',
  CITY = 'City',
  STATE = 'State',
  COUNTRY = 'Country',
  POSTAL_CODE = 'Postal Code',
  FIRST_LOGIN_DATE = 'First Login Date',
  CURRENT_LOCATION = 'Last Location',
  CARRIER_NAME = 'Carrier Name',
  EQUIPMENT_LIST = 'Equipment Types',
  EQUIPMENT_LIST_AVAILABLE = 'Equipment List Available',
  DEADHEAD = 'Deadhead',
  PICKUP_LOCATION = 'Pickup Address',
  DROPOFF_LOCATION = 'Dropoff Address',
  HOME_LOCATION_AVAILABLE = 'Home Location Available',
  PICKUP_DATE = 'Pickup Date',
  PICKUP_DATE_START = 'Pickup Date Start',
  PICKUP_DATE_END = 'Pickup Date End',
  PICKUP_DATE_ENTERED = 'Pickup Date Entered',
  SEARCHED_CITY = 'Searched Cities',
  SEARCHED_STATE = 'Searched States',
  PICKUP_CITY = 'Pickup City',
  PICKUP_STATE = 'Pickup State',
  LOAD_SOURCE = 'Load Source',
  COMPANY_NAME = 'Company Name',
  CARRIER_COMPANY_NAME = 'Carrier Company Name',
  DOCUMENT_TYPE = 'Document Type',
  DOCUMENT_TYPES = 'Document Types',
  PER_MILE_RATE = 'Rate Per Mile',
  POSTED_RATE = 'Posted Rate',
  CURRENCY = 'Currency',
  PAGE_SOURCE = 'Page Source',
  PAGE_NUMBER = 'Results Page Number',
  TOTAL_RESULTS_COUNT = 'Total Results Count',
  DROPOFF_DATE = 'Drop-off Date',
  DROPOFF_DATE_START = 'Drop-off Date Start',
  DROPOFF_DATE_END = 'Drop-off Date End',
  PICKUP_RADIUS = 'Pickup Radius',
  DROPOFF_RADIUS = 'Drop-off Radius',
  WEIGHT = 'Weight',
  ROUTE_TYPE = 'Route Type',
  NEW_USER = 'New User',
  ROLE_SELECTED = 'Role Selected',
  EQUIPMENT_SELECTED = 'Equipment Selected',
  CURRENT_LOCATION_USED = 'Current Location Used',
  EXPERIMENT_BRANCH_NAME = 'Experiment Branch Name',
  EXPERIMENT_ACTIVATION_VALUE = 'Experiment Activation Value',
  NETLIFY_SITE_NAME = 'Netlify Site Name',
  AMOUNT = 'Amount',
  EXPERIMENT_VIEWED = 'Experiment Viewed',
  NEW_AMOUNT = 'New Amount',
  EXISTING_EMAIL = 'Existing Email',
  COMMODITY = 'Commodity',
  PICKUP_TIME_START = 'Pickup Time Start',
  PICKUP_TIME_END = 'Pickup Time End',
  DROPOFF_TIME_START = 'Drop-off Time Start',
  DROPOFF_TIME_END = 'Drop-off Time End',
  LENGTH = 'Length',
  NOTES = 'Notes',
  LOAD_REQUIREMENTS = 'Load Requirements',
  FREIGHT_TYPE = 'Freight Type',
  LOAD_INTEGRATION_TYPE = 'Load Integrated Type',
  BID_STATUS = 'Bid Status',
  LAT = 'lat',
  LNG = 'lng',
  T_CODE = 'T-Code',
  ACTION = 'Action',
  DOT = 'DOT',
  MC = 'MC',
  LOAD_INTERACTION_TRIGGER = 'Load Interaction trigger',
  IS_COMING_FROM_EMAIL = 'From Email',
  REQUEST_ID = 'Request Id',
  VIN = 'Trailer VIN',
  UNIT_ID = 'Unit ID',
}

export enum MIXPANEL_EVENTS {
  // Load Action Related
  SHOW_PHONE_NUMBER = 'Show Phone Number Clicked',
  CALL_BUTTON_CLICKED = 'Call Button Clicked',
  BROKER_EMAIL_CLICKED = 'Broker Email Clicked',
  LOAD_CARD_EXPANDED = 'Load Card Expanded',
  SHARE_BUTTON_CLICKED = 'Share Button Clicked',
  BOOK_BUTTON_CLICKED = 'Book Button Clicked',
  BOOK_BUTTON_CONFIRMATION_BUTTON_CLICKED = 'Book Now Confirmation Button Clicked',
  OPENED_SEND_EMAIL = 'Opened Send Email',
  SEND_EMAIL_CLICKED = 'Send Email clicked',
  EMAIL_MESSAGE_EDITED = 'Email message edited',
  EMAIL_CONTACT_INFO_CHANGED = 'Email contact info changed',
  FIND_NEXT_LOAD_BUTTON_CLICKED = 'Find Next Load Button Clicked',
  LOADS_NEAR_ME_BUTTON_CLICKED = 'Loads Near Me Button Clicked',
  HIGH_PAYING_LOADS_NEAR_ME_BUTTON_CLICKED = 'High Paying Loads Near Me Button Clicked',
  VIEW_RATE_BUTTON_CLICKED = 'View Rate Button Clicked',
  CHR_T_CODE_SUBMITTED = 'CHR T# submitted',
  CHR_REDIRECT_CLICKED = 'CHR Redirect Clicked',
  CHR_INVALID_T_CODE = 'CHR invalid T#',
  CHR_RATE_CHANGED_UPON_BOOKING = 'CHR Rate Changed Upon Booking',
  CHR_BOOKING_REFRESH_SEARCH_PRESSED = 'CHR Booking Refresh Search Button Clicked',
  T_CODE = 'T-Code',
  EMPTY_DETAILS_CONTINUE_BUTTON_CLICKED = 'Empty Details Continue Button Clicked',
  EMPTY_DETAILS_CANCEL_BUTTON_CLICKED = 'Empty Details Cancel Button Clicked',
  EMPTY_DETAILS_SKIP_BUTTON_CLICKED = 'Empty Details Skip Button Clicked',
  // Onboarding events
  OPENED_ONBOARDING = 'Opened Onboarding',
  TOS_SIGNED = 'TOS Signed',
  DOT_ENTERED = 'DOT Entered',
  ROLE_SELECTED = 'Role Selected',
  CONTACT_INFO_CONFIRMED = 'Contact information confirmed',
  EQUIPMENT_SELECTED = 'Equipment Selected',
  FLEET_SETUP_COMPLETED = 'Fleet Setup Completed',
  ONBOARDING_COMPLETED = 'Onboarding Completed',
  // Walkthrough/tutorials events
  WALKTHROUGH_VIEWED = 'Walkthrough Viewed',
  WALKTHROUGH_CANCELLED = 'Walkthrough Cancelled',
  // Splash screen events
  SPLASH_OPENED = 'Splash Page Opened',
  SPLASH_COMPLETED = 'Splash Page Completed',
  // Landing page events
  LANDING_PAGE_COMPLETED = 'Landing Page Completed',
  // Search Related
  SEARCH = 'Searched Results Opened',
  SEARCH_NEW_PAGE = 'Searched Results New Page Loaded',
  APPLIED_FILTER = 'Applied Filter',
  APPLIED_SORT = 'Applied Sort',
  CERTIFIED_FILTER_APPLIED = 'Certified filter applied',
  NEXT_LOAD_PICKUP_DATE_ADDED = 'Pickup Location and Date Added For Next Load',
  HOME_LOCATION_ADDED = 'Home Location Added',
  // RAL Related
  RAL_CURRENT_LOADS_VIEWED = 'RAL Current Loads Viewed',
  RAL_SUBMITTED = 'Load Requested',
  RAL_FILTER_APPLIED = 'Request Filter Applied',
  LOAD_REQUEST_EDITED = 'Load Request Edited',
  LOAD_REQUEST_DELETED = 'Load Request Deleted',
  NOTIFY_ME_BUTTON_CLICKED = 'Notify Me Button Clicked',
  REQUEST_MY_NEXT_LOAD_BUTTON_CLICKED = 'Request My Next Load Button Clicked',
  // Page Related
  LOAD_SEARCH_PAGE_OPENED = 'Load Search Page Opened',
  LOAD_DETAILS_PAGE_OPENED = 'Load Details Page Opened',
  DISPATCH_PAGE_OPENED = 'Dispatch/My Loads Page Opened',
  DRIVERS_PAGE_OPENED = 'Drivers Page Opened',
  DRIVER_INFO_PAGE_OPENED = 'Driver Info Page Opened',
  RAL_PAGE_OPENED = 'RAL Page Opened',
  RAL_INFO_PAGE_OPENED = 'RAL Info Page Opened',
  SETTINGS_PAGE_OPENED = 'Settings Page Opened',
  NOTIFICATIONS_PAGE_OPENED = 'Notifications Page Opened',
  TAKE_ME_HOME_BUTTON_CLICKED = 'Take Me Home Button Clicked',

  // Preferred Lane
  ADD_PREFERRED_LANE = 'Preferred Lane Entered',
  // SEARCH_PREFERRED_LANE = 'Preferred Lane Searched',
  EDIT_PREFERRED_LANE = 'Preferred Lane Edited',
  DELETE_PREFERRED_LANE = 'Preferred Lane Deleted',

  // Bid
  SUBMIT_OFFER_CLICKED = 'Submit Offer Clicked',
  OFFER_SUBMITTED = 'Offer Submitted',
  EDIT_OFFER_CLICKED = 'Edit Offer Clicked',
  OFFER_EDITED = 'Offer Edited',
  OFFER_CANCELLED = 'Offer Cancelled',

  // Account Details
  OPENED_ACCOUNT_DETAILS_REQUIRED = 'Opened Account Details Required',
  ACCOUNT_DETAILS_REQUIRED_CANCEL_BUTTON_CLICKED = 'Account Details Required Cancel button clicked',
  ACCOUNT_DETAILS_REQUIRED_CONTINUE_BUTTON_CLICKED = 'Account Details Required Continue button clicked',

  // Other
  HELP_BUTTON_CLICKED = 'Help Button Clicked',
  // FTF Registration flow
  FTF_LANDING_PAGE_OPENED = 'FTF Landing Page Opened',
  FTF_GET_STARTED_BUTTON_CLICKED = 'FTF Get Started Button Clicked',
  FTF_ALREADY_HAVE_ACCOUNT_LINK_CLICKED = 'FTF Already Have Account Link Clicked',
  FTF_DONT_HAVE_ACCOUNT_LINK_CLICKED = 'FTF Dont Have Account Link Clicked',
  FTF_FORGOT_EMAIL_CLICKED = 'FTF Forgot Email Link Clicked',
  FTF_TOS_PP_LINK_CLICKED = 'FTF Terms Of Service and Privacy Policy Link Clicked',
  FTF_USER_LOGGED_IN = 'FTF User Logged In Successfully',
  FTF_USER_ACCOUNT_CREATED = 'FTF User Account Created Successfully',
  FTF_FORGOT_PASSWORD_CLICKED = 'FTF Forgot Password Clicked',
  FTF_FORGOT_PASSWORD_SEND_EMAIL_CLICKED = 'FTF Forgot Password Send Email Clicked',
  FTF_CREATE_NEW_ACCOUNT_CLICKED = 'FTF Create New Account Clicked',

  // FleetPulse events
  FP_TRAILER_MAP_MARKER_CLICKED = 'Trailer Map Marker Clicked',
  FP_TRAILER_LOAD_MAP_MARKER_CLICKED = 'Recommended Load Map Marker Click',
  FP_VIEW_ALL_RECOMENDATIONS_CLICKED = 'FP Trailer List View All Recomendations Clicked',
  FP_VIEW_TRAILER_RECOMMENDED_LOAD = 'View Trailer Recommended Load',
  FP_VIEW_TRAILER_RECOMMENDATIONS_ON_MAP = 'Viewed Trailer Recommendations on Map',
  FP_VIEW_POTENTIAL_REVENUE_CLICKED = 'FP Potential Revenue Clicked',
  FP_POTENTIAL_REVENUE_CALCULATION = 'FP Potential Revenue Calculation',
  FP_TRAILERS_FOR_RENT_LISTED = 'FP Trailers For Rent Listed',
  FP_TRAILERS_DETAILS_PAGE_OPENED = 'FP Trailers Details Page Opened',
  FP_RESERVE_TRAILER_BUTTON_CLICKED = 'FP Reserve Trailer Button Clicked',
  FP_VIEW_ALL_TRAILER_RECOMMENDATIONS = 'View all Trailer recommendations',
  FP_TRAILER_DETAILS_VIEWED = 'Trailer Details Viewed',
}

export const MIXPANEL_EVENT_VALUE = {
  IS_COMING_FROM_EMAIL: {
    YES: 'Yes' as 'Yes',
    NO: 'No' as 'No',
  },
} as const;

export const PREV_ROUTE_NAME = {
  SEARCH: 'search',
  ACTIVE_LOADS: 'activeLoads',
  SHARED_LOADS: 'sharedLoads',
  PAST_LOADS: 'pastLoads',
  CANCELLED_LOADS: 'cancelledLoads',
  DRIVER_DETAIL: 'driverDetail',
  DRIVER_LIST: 'driversList',
  RAL_DETAIL: 'ralDetail',
  RAL_LIST: 'ralList',
  NOTIFICATIONS: 'notifications',
  ONBOARDING: 'onboarding',
  TRAILER_LIST: 'trailerList',
  MATCH_LOAD_DETAIL: 'matchLoadDetail',
};

export const PAGE_SOURCE = {
  SEARCH: 'Search Results',
  ACTIVE_LOADS: 'Active Loads',
  SHARED_LOADS: 'Shared Loads',
  PAST_LOADS: 'Past Loads',
  CANCELLED_LOADS: 'Cancelled Loads',
  DRIVER_DETAIL: 'Driver Details',
  DRIVER_LIST: 'Drivers List',
  RAL_LIST: 'RAL List',
  RAL_DETAIL: 'RAL Details',
  LOAD_DETAIL: 'Load Details',
  NOTIFICATIONS: 'Notifications',
  SETTING: 'Settings',
  ONBOARDING: 'Onboarding',
  DIRECT: 'Direct',
  NAV_BAR: 'Nav Bar',
  SIDE_HELP_BUTTON: 'Side Help Button',
  MODAL: 'Modal',
  LOGIN: 'Login',
  EXISTING_EMAIL: 'Existing Email',
  FP_RESERVE_A_TRAILER: 'FP Reserve A Trailer',
  FP_LIST_MY_TRAILER: 'FP List My Trailer',
  FP_MY_TRAILER_LIST: 'FP My Trailer List',
};
