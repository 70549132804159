import axios from 'axios';
import 'firebase/auth';
import { ApiFail } from 'utils/ErrorService';
import config from 'config';
import Load from 'models/dataStructures/Load';
import Match from 'models/dataStructures/Match';
import { FO_USER_TYPES } from 'constants/General';
import { getTokenHeader } from './GetTokenHeader';

class ApiLayer {
  /* V2 endpoints */

  // Matches
  /**
   * Get a match.
   * @param {string} matchId
   */
  static async getMatch(matchId) {
    if (!matchId) {
      return Promise.reject(new Error('Invalid matchId provided.'));
    }
    const tokenHeader = await getTokenHeader();
    return axios
      .get(`${config.apiUrlV2}/matches/${matchId}`, { headers: tokenHeader })
      .then((response) => {
        if (response.data.data) {
          try {
            return new Match(response.data.data);
          } catch (error) {
            throw new Error(`Error casting to Match: ${error}`);
          }
        }
      })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }

  // Loads
  /**
   * Return single load
   * @param {string} brokerId
   * @param {string} status
   */
  static async getLoad(loadId, tCode = '', source) {
    if (!loadId) {
      return Promise.reject(new Error('Invalid loadId provided.'));
    }
    let url = `${config.apiUrlV2}/loads/${loadId}`;
    let params = {};
    if (tCode) {
      params['tCode'] = tCode;
    }
    if (source) {
      params['source'] = source;
    }
    const tokenHeader = await getTokenHeader();
    return axios
      .get(url, { headers: tokenHeader, params })
      .then((response) => {
        if (response.data && response.data.data) {
          return new Load(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
        ApiFail(error);
        throw new Error(error);
      });
  }

  /**
   * Return single convoy load
   * @param {string} loadId
   */
  static async getConvoyLoad(loadId) {
    if (!loadId) {
      return Promise.reject(new Error('Invalid loadId provided.'));
    }
    const tokenHeader = await getTokenHeader();
    return axios
      .get(`${config.apiUrlV2}/search/convoy/${loadId}/details`, { headers: tokenHeader })
      .then((response) => {
        if (response.data && response.data.data) {
          return new Load(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
        ApiFail(error);
        throw new Error(error);
      });
  }

  /**
   * Return single load with full information
   * @param {string} loadId
   */
  static async getLoadDetails(loadId) {
    if (!loadId) {
      return Promise.reject(new Error('Invalid loadId provided.'));
    }
    const tokenHeader = await getTokenHeader();
    return axios
      .get(`${config.apiUrlV2}/search/${loadId}/details`, { headers: tokenHeader })
      .then((response) => {
        if (response.data && response.data.data) {
          return new Load(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
        ApiFail(error);
        throw new Error(error);
      });
  }

  /**
   * Return single load with full information
   * @param {string} loadId
   */
  static async getMatchesForLoads(loadIds = []) {
    const tokenHeader = await getTokenHeader();
    return axios
      .post(
        `${config.apiUrlV2}/matches/get-shared-loads`,
        { loads: loadIds },
        { headers: tokenHeader },
      )
      .then((response) => {
        if (response.data && response.data.data) {
          return response.data.data;
        }
      })
      .catch((error) => {
        console.log(error);
        ApiFail(error);
        throw new Error(error);
      });
  }

  /**
   * Return single load with full information
   * @param {string} loadId
   */
  static async getOffersForLoads(loadIds = []) {
    const tokenHeader = await getTokenHeader();
    return axios
      .post(`${config.apiUrlV2}/search/offers`, { loadIds }, { headers: tokenHeader })
      .then((response) => {
        if (response.data && response.data.data) {
          return response.data.data;
        }
      })
      .catch((error) => {
        console.log(error);
        ApiFail(error);
        throw new Error(error);
      });
  }

  /**
   * Share a load
   * @param {object} load
   */
  static async shareLoad(load) {
    if (!load || !load.loadId) {
      return Promise.reject(new Error('Invalid load provided.'));
    }
    const tokenHeader = await getTokenHeader();
    return axios
      .post(
        `${config.apiUrlV2}/loads/${load?.loadId}/share/dispatcher`,
        load?.loadConvoy ? { convoy: true } : {},
        { headers: tokenHeader },
      )
      .then((response) => {
        if (response.data) {
          return new Load(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
        ApiFail(error);
        throw new Error(error);
      });
  }

  /**
   * Updating me settings
   */
  static async meUpdate(uid, settings) {
    if (!settings) {
      return Promise.reject(new Error('Invalid settings provided.'));
    }
    const tokenHeader = await getTokenHeader();

    return axios
      .post(`${config.apiUrlV2}/users/me`, { uid, ...settings }, { headers: tokenHeader })
      .then((response) => {
        if (response.data) {
          return response.data.data;
        }
      })
      .catch((error) => {
        console.log(error);
        ApiFail(error);
        throw new Error(error);
      });
  }

  // User
  /**
   * Creates a new user.
   * @param {string} email
   * @param {string} password
   * @param {string} userType One of FO_USER_TYPES
   * @param {boolean} agreeToTermsAndConditions
   */
  static createNewUser(email, password, userType, agreedToTermsAndConditions) {
    if (!email) {
      return Promise.reject(new Error('Invalid email provided.'));
    }
    if (!password) {
      return Promise.reject(new Error('Invalid password provided.'));
    }
    if (!userType || (userType !== FO_USER_TYPES.broker && userType !== FO_USER_TYPES.driver)) {
      return Promise.reject(
        new Error(
          `Invalid user type provided. Should be one of ${FO_USER_TYPES.broker}, ${FO_USER_TYPES.driver}`,
        ),
      );
    }
    const data = {
      email,
      password,
      userType,
      agreedToTermsAndConditions,
    };
    return axios({
      data,
      method: 'POST',
      url: `${config.apiUrlV2}/users/`,
    })
      .then((response) => {
        if (response.data.data) {
          return { user: response.data.data };
        }
        return {};
      })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }

  /**
   * Gets info of the currently logged in user.
   */
  static async me() {
    const tokenHeader = await getTokenHeader();
    return axios
      .get(`${config.apiUrlV2}/users/me`, { headers: tokenHeader })
      .then((response) => {
        if (response.data && response.data.data) {
          return { user: response.data.data };
        }
        return {};
      })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }

  /**
   * Gets a user's profile picture
   */
  static async getProfilePicture(pictureId: string) {
    const tokenHeader = await getTokenHeader();
    const url = `${config.apiUrlV2}/users/${pictureId}/profile-picture`;

    return axios
      .get(url, { headers: tokenHeader, responseType: 'blob' })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }

  // Auth
  /**
   * Checks if big roads SSO token is valid or not.
   * @param {string} email
   * @param {string} token BigRoad SSO token
   * @param {string} userId bypass email login
   */
  static checkBigRoadToken(data) {
    return axios({
      method: 'POST',
      url: `${config.apiUrlV2}/auth/bigroad`,
      data,
    })
      .then((response) => response.data.data)
      .catch((error) => {
        ApiFail(error);
        throw error;
      });
  }

  // Auth ELD Loads user
  /**
   * Checks if ELD Loads SSO token is valid or not.
   * @param {string} token ELD Loads SSO token
   * @param {string} userId bypass email login
   */
  static checkEldLoadsToken(data) {
    return axios({
      method: 'POST',
      url: `${config.apiUrlV2}/auth/eldloads`,
      data,
    })
      .then((response) => response.data.data)
      .catch((error) => {
        ApiFail(error);
        throw error;
      });
  }

  // Auth EZLoadz user
  /**
   *
   * @param {string} token EZ Loads SSO token
   * @param {string} userId bypass email login
   */
  static checkEZLoadz(data) {
    return axios({
      method: 'POST',
      url: `${config.apiUrlV2}/auth/ezloadz`,
      data,
    })
      .then((response) => response.data.data)
      .catch((error) => {
        ApiFail(error);
        throw error;
      });
  }

  // Auth
  /**
   * Checks if Switchboard SSO token is valid or not.
   * @param {string} email
   * @param {string} token Switchboard SSO token
   * @param {string} userId bypass email login
   */
  static checkSwitchboardToken(data) {
    return axios({
      method: 'POST',
      url: `${config.apiUrlV2}/auth/switchboard`,
      data,
    })
      .then((response) => response.data.data)
      .catch((error) => {
        ApiFail(error);
        throw error;
      });
  }

  // Auth One View user
  /**
   * Checks if One View SSO token is valid or not.
   * @param {string} token ELD Loads SSO token
   * @param {string} fmUserId ELD Loads SSO userId
   * @param {string} userId bypass email login
   */
  static checkFreightMateToken(data) {
    return axios({
      method: 'POST',
      url: `${config.apiUrlV2}/auth/freightmate`,
      data,
    })
      .then((response) => response.data.data)
      .catch((error) => {
        ApiFail(error);
        throw error;
      });
  }

  /**
   * Log In FleetTrack user
   * @param {string} email
   * @param {string} password
   * @param {string} userId bypass email login
   */
  static fleetTrackLogin(data) {
    return axios({
      method: 'POST',
      url: `${config.apiUrlV2}/auth/fleettrack`,
      data,
    })
      .then((response) => response.data.data)
      .catch((error) => {
        ApiFail(error);
        if (error && (error.response.status === 400 || error.response.status === 503)) {
          throw error.response; // handle this response in partner store
        } else {
          throw new Error(error);
        }
      });
  }

  /**
   * Register FleetTrack user
   * @param {string} email
   * @param {string} password
   */
  static fleetTrackRegister(email: string, password: string, accountId: string, dotNumber: string) {
    return axios({
      method: 'POST',
      url: `${config.apiUrlV2}/fleettrack/register`,
      data: {
        email,
        password,
        accountId,
        dotNumber,
      },
    })
      .then((response) => response.data.data)
      .catch((error) => {
        ApiFail(error);
        if (error && (error.response.status === 400 || error.response.status === 503)) {
          throw error.response; // handle this response in partner store
        } else {
          throw new Error(error);
        }
      });
  }

  /**
   * Log In FleetPulse user
   * @param {string} id
   * @param {string} token
   */
  static fleetPulseLogin(data) {
    return axios({
      method: 'POST',
      url: `${config.apiUrlV2}/auth/fleetpulse`,
      data,
    })
      .then((response) => response.data.data)
      .catch((error) => {
        ApiFail(error);
        if (error && (error.response.status === 400 || error.response.status === 503)) {
          throw error.response; // handle this response in partner store
        } else {
          throw new Error(error);
        }
      });
  }

  /**
   * Triggers SNS event to notify FleetTrack
   * users that their email is duplicated
   * @param {string} email
   */
  static notifyDuplicatedEmail(email: string, dotNumber?: string, accountId?: string) {
    return axios({
      method: 'POST',
      url: `${config.apiUrlV2}/fleettrack/notify`,
      data: {
        email,
        dotNumber,
        accountId,
      },
    })
      .then((response) => response.data.data)
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }

  /**
   * Update users settings.
   * @param {object} settings
   */
  static async updateSettings(settings) {
    if (!settings) {
      return Promise.reject(new Error('Invalid settings provided.'));
    }
    const tokenHeader = await getTokenHeader();
    return axios({
      method: 'POST',
      url: `${config.apiUrlV2}/users/me/settings`,
      headers: tokenHeader,
      data: settings,
    })
      .then((response) => {
        if (response.data && response.data.data) {
          return { user: response.data.data };
        }
        return {};
      })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }

  /**
   * Update driver settings.
   * @param {object} settings
   */
  static async updateDriverSettings(settings) {
    if (!settings) {
      return Promise.reject(new Error('Invalid settings provided.'));
    }
    const tokenHeader = await getTokenHeader();
    return axios({
      method: 'PATCH',
      url: `${config.apiUrlV2}/users/driver/settings`,
      headers: tokenHeader,
      data: settings,
    })
      .then((response) => {
        if (response.data && response.data.data) {
          return { user: response.data.data };
        }
        return {};
      })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }

  // Truck
  // Post
  static async postMyTruck(data) {
    if (!data) {
      return Promise.reject(new Error('Invalid data provided'));
    }
    const tokenHeader = await getTokenHeader();
    return axios({
      method: 'POST',
      url: `${config.apiUrlV2}/ral/`,
      headers: tokenHeader,
      data,
    }).catch((error) => {
      ApiFail(error);
      throw new Error(error);
    });
  }

  // Truck
  // DELETE
  static async deletePostedTruck(postedTruckId, personId?: string) {
    const tokenHeader = await getTokenHeader();
    const url = `${config.apiUrlV2}/ral/${postedTruckId}`;

    return axios({
      method: 'DELETE',
      url,
      headers: tokenHeader,
      data: personId ? { personId } : undefined,
    }).catch((error) => {
      ApiFail(error);
      throw new Error(error);
    });
  }

  // / //////////////////////////////////////////////////////////////////// 3rd Party
  // --------------------------------------------------------------------------
  static getDistance(
    originLat,
    originLon,
    destinationLat,
    destinationLong,
  ): Promise<google.maps.DistanceMatrixResponse> {
    const origin = new google.maps.LatLng(originLat, originLon);
    const destination = new google.maps.LatLng(destinationLat, destinationLong);
    const service = new google.maps.DistanceMatrixService();
    return new Promise((resolve, reject) => {
      service.getDistanceMatrix(
        {
          origins: [origin],
          destinations: [destination],
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.IMPERIAL,
        },
        (response, status) => {
          if (status === google.maps.DistanceMatrixStatus.OK) {
            resolve(response);
          } else {
            reject(status);
          }
        },
      );
    });
  }

  static getDistances(origins, destinations): Promise<google.maps.DistanceMatrixResponse> {
    const gOrigins = origins.map((origin) => new google.maps.LatLng(origin.lat, origin.lng));
    const gDestinations = destinations.map(
      (destination) => new google.maps.LatLng(destination?.lat, destination?.lng),
    );
    const service = new google.maps.DistanceMatrixService();
    return new Promise((resolve, reject) => {
      service.getDistanceMatrix(
        {
          origins: gOrigins,
          destinations: gDestinations,
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.IMPERIAL,
        },
        (response, status) => {
          if (status === google.maps.DistanceMatrixStatus.OK) {
            resolve(response);
          } else {
            reject(status);
          }
        },
      );
    });
  }

  static getDirection(origin, destination): Promise<google.maps.DirectionsResult> {
    const service = new google.maps.DirectionsService();
    return new Promise((resolve, reject) => {
      service.route(
        {
          origin: new google.maps.LatLng(origin.lat, origin.lng),
          destination: new google.maps.LatLng(destination.lat, destination.lng),
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            resolve(response);
          } else {
            reject(status);
          }
        },
      );
    });
  }

  static getCurrentCoordinates() {
    return axios({
      method: 'POST',
      url: `${config.geolocationAPI}`,
    })
      .then((response) => response.data)
      .catch((error) => {
        ApiFail(error);
        console.log('Error getting current coordinates.');
        throw new Error(error);
      });
  }

  // RAL matches
  /**
   * Get RAL matches.
   * @param {string} ralId
   */
  static async getRALMatches(ralId) {
    if (!ralId) {
      return Promise.reject(new Error('Invalid RAL ID provided.'));
    }
    const tokenHeader = await getTokenHeader();
    return axios
      .get(`${config.apiUrlV2}/matches/ral/${ralId}`, { headers: tokenHeader })
      .then((response) => {
        if (response.data.data) {
          try {
            return response.data.data;
          } catch (error) {
            throw new Error(`Error : ${error}`);
          }
        }
      })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }

  static async sendTrackingLink(data) {
    const tokenHeader = await getTokenHeader();
    return axios
      .post(`${config.apiUrlV2}/users/me/trackingLink/send`, data, { headers: tokenHeader })
      .then((response) => {
        if (response.data.data) {
          try {
            return response.data.data;
          } catch (error) {
            throw new Error(`Error : ${error}`);
          }
        }
      })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }

  // Offer Sources
  /**
   * Get Offer Sources
   */
  static async getOfferSources() {
    const tokenHeader = await getTokenHeader();
    return axios
      .get(`${config.apiUrlV2}/offers/sources`, { headers: tokenHeader })
      .then((response) => {
        if (response.data.data) {
          try {
            return response.data.data;
          } catch (error) {
            throw new Error(`Error : ${error}`);
          }
        }
      })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }

  static async loadCountByDate(data) {
    const tokenHeader = await getTokenHeader();
    return axios
      .post(`${config.apiUrlV2}/search/summary`, data, { headers: tokenHeader })
      .then((response) => {
        if (response.data) {
          try {
            return response.data;
          } catch (error) {
            throw new Error(`Error : ${error}`);
          }
        }
      })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }

  static async loadListBrokers(data) {
    const tokenHeader = await getTokenHeader();
    return axios
      .post(`${config.apiUrlV2}/search/brokers`, data, {
        headers: tokenHeader,
        retry: 5,
        retryDelay: 1000,
      })
      .then((response) => {
        if (response.data) {
          try {
            return response.data.data;
          } catch (error) {
            throw new Error(`Error : ${error}`);
          }
        }
      })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }

  static async markReadMatch(matchId) {
    if (!matchId) {
      return Promise.reject(new Error('Invalid matchId provided.'));
    }
    const tokenHeader = await getTokenHeader();
    return axios
      .patch(`${config.apiUrlV2}/matches/${matchId}/read`, null, { headers: tokenHeader })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }

  static async getOnboardingSuggestedLanes() {
    const tokenHeader = await getTokenHeader();
    return axios
      .get(`${config.apiUrlV2}/users/me/onboardingSuggestedLanes`, { headers: tokenHeader })
      .then((response) => {
        if (response.data.data) {
          try {
            return response.data.data;
          } catch (error) {
            throw new Error(`Error : ${error}`);
          }
        }
      })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }

  /**
   * Get company details from USDOT
   */
  static async getDOTDetails(dotNumber) {
    const tokenHeader = await getTokenHeader();
    return axios
      .get(`${config.apiUrlV2}/dot/${dotNumber}/details`, { headers: tokenHeader })
      .then((response) => {
        if (response.data.data) {
          try {
            return response.data.data;
          } catch (error) {
            throw new Error(`Error : ${error}`);
          }
        }
      })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }

  /**
   * Get company details from MC number
   */
  static async getMCDetails(mcNumber) {
    const tokenHeader = await getTokenHeader();
    return axios
      .get(`${config.apiUrlV2}/mc/${mcNumber}/details`, { headers: tokenHeader })
      .then((response) => {
        if (response.data.data) {
          try {
            return response.data.data;
          } catch (error) {
            throw new Error(`Error : ${error}`);
          }
        }
      })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }

  /**
   * Get roles & permissions from onboarding invite
   */
  static async getDetailsFromInvite() {
    const tokenHeader = await getTokenHeader();
    return axios
      .get(`${config.apiUrlV2}/users/check-user-invitation`, { headers: tokenHeader })
      .then((response) => {
        if (response.data.data) {
          try {
            return response.data.data;
          } catch (error) {
            throw new Error(`Error : ${error}`);
          }
        }
      })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }

  /**
   * Submits onboarding data for a new user.
   * @param {object} data
   */
  static async onboardUser(data) {
    if (!data) {
      return Promise.reject(new Error('Invalid data provided.'));
    }
    const tokenHeader = await getTokenHeader();
    return axios({
      data,
      method: 'POST',
      url: `${config.apiUrlV2}/users/save-onboarding-step`,
      headers: tokenHeader,
    })
      .then((response) => {
        if (response.data && response.data.data) {
          return { user: response.data.data };
        }
        return {};
      })
      .catch((error) => {
        const err_msg = error.response.data.msg;
        // BE sends back generic error so look for phone & return custom error object
        if (err_msg.includes('phone')) {
          throw { phone: 'A user with this phone number already exists' };
        }
        ApiFail(error);
        throw Error(error.response.data);
      });
  }

  /**
   * Get fleet users that are already onboarded
   */
  static async getOnboardedUsers() {
    const tokenHeader = await getTokenHeader();
    return axios
      .get(`${config.apiUrlV2}/users/users-with-same-dot-or-fleet-id`, {
        headers: tokenHeader,
      })
      .then((response) => {
        if (response.data.data) {
          try {
            return response.data.data;
          } catch (error) {
            throw new Error(`Error : ${error}`);
          }
        }
      })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }
  /**
   * Get users that are not yet onboarded
   */
  static async getUsersNotOnboarded(params) {
    const tokenHeader = await getTokenHeader();
    return axios
      .get(`${config.apiUrlV2}/users/sibling-trucks`, {
        params,
        headers: tokenHeader,
      })
      .then((response) => {
        if (response.data.data) {
          try {
            return response.data.data;
          } catch (error) {
            throw new Error(`Error : ${error}`);
          }
        }
      })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }

  /**
   * Send invite to users.
   */
  static async inviteUsers(data) {
    if (!data) {
      return Promise.reject(new Error('Invalid data provided.'));
    }
    const tokenHeader = await getTokenHeader();

    return axios({
      data: data,
      method: 'POST',
      url: `${config.apiUrlV2}/fleets/invite`,
      headers: tokenHeader,
    })
      .then((response) => {
        if (response.data && response.data.data) {
          console.log(response.data);
        }
      })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }

  /**
   * Send feedback.
   */
  static async sendFeedback(data) {
    if (!data) {
      return Promise.reject(new Error('Invalid data provided.'));
    }
    const tokenHeader = await getTokenHeader();

    return axios({
      data,
      method: 'POST',
      url: `${config.apiUrlV2}/feedback/`,
      headers: tokenHeader,
    })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }

  static async triggerSNSEvent(data) {
    const tokenHeader = await getTokenHeader();
    return axios
      .post(`${config.apiUrlV2}/events/sns`, data, { headers: tokenHeader })
      .then((response) => {
        if (response.data) {
          try {
            return response.data;
          } catch (error) {
            throw new Error(`Error : ${error}`);
          }
        }
      })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }
  /**
   * Delete a driver.
   */
  static async deleteDriver(fleetId: string, driverId: string) {
    const tokenHeader = await getTokenHeader();
    const url = `${config.apiUrlV2}/fleets/${fleetId}/users/${driverId}`;

    return axios({
      method: 'DELETE',
      url,
      headers: tokenHeader,
      data: null,
    }).catch((error) => {
      ApiFail(error);
      throw new Error(error);
    });
  }

  /**
   * Delete a notification.
   */
  static async deleteNotification(notificationId: string) {
    const tokenHeader = await getTokenHeader();
    const url = `${config.apiUrlV2}/notifications/${notificationId}`;

    return axios({
      method: 'DELETE',
      url,
      headers: tokenHeader,
      data: null,
    }).catch((error) => {
      ApiFail(error);
      throw new Error(error);
    });
  }

  /**
   * Change match status.
   */
  static async markLoadComplete(matchId: string) {
    const tokenHeader = await getTokenHeader();
    const url = `${config.apiUrlV2}/matches/${matchId}/complete`;

    return axios({
      method: 'PATCH',
      url,
      headers: tokenHeader,
      data: { status },
    }).catch((error) => {
      ApiFail(error);
      throw new Error(error);
    });
  }

  static async resetPassword(email: string) {
    return axios
      .post(`${config.apiUrlV2}/fleettrack/recover`, { email })
      .then((response) => {
        if (response.data && response.data.data) {
          return response.data.data;
        }
        return {};
      })
      .catch((error) => {
        ApiFail(error);
        if (error && (error.response.status === 400 || error.response.status === 503)) {
          throw error.response; // handle this response in user store
        } else {
          throw new Error(error);
        }
      });
  }

  static async sendEmail(data, loadId: string) {
    if (!data || !loadId) {
      return Promise.reject(new Error('Invalid data provided.'));
    }
    const tokenHeader = await getTokenHeader();

    return axios({
      data,
      method: 'POST',
      url: `${config.apiUrlV2}/loads/${loadId}/contact/email`,
      headers: tokenHeader,
    })
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        ApiFail(error);
        throw new Error(error);
      });
  }
}

export default ApiLayer;
