import FreightMateLoadsLogo from 'assets/images/logos/FreightMate-logo.svg';
import production from './production';
import staging from './staging';
import development from './development';
import test from './test';

const configVariants = {
  production,
  staging,
  development,
  test,
};
const config = {
  freshworksId: 43000002264,
  partner: {
    url: 'https://freight-mate.pedigreetechnologies.com',
    name: 'Freight-Mate',
    partnerName: 'OneView',
    logo: FreightMateLoadsLogo,
  },
  ...configVariants[process.env.NODE_ENV || 'development'],
};

export default config;
