const config = {
  clarityId: '86ryxvfrcn', // UPDATE WHEN AVAILABLE
  datadog: {
    applicationId: 'e28a2019-424e-4443-a3e9-43ce938600d5',
    clientToken: 'pubd5db3d3879d7141f9ba674e7919fc8c6',
    service: 'fleetpulse-|-staging',
  },
  appUrl: 'https://edge01.fleet-pulse.com',
  growthBookSdkClientKey: 'sdk-4hwOMnOq1ZH4o2ZC',
};

export default config;
