import config from 'config';
import { MIXPANEL_KEYS } from 'constants/Mixpanel';
import { getSplitTests } from 'services/APIServices/NetlifyServices';

function getCookieValue(cookieName: string): string | undefined {
  return document.cookie.match('(^|;)\\s*' + cookieName + '\\s*=\\s*([^;]+)')?.pop();
}

async function isSplitTestActive() {
  try {
    const splitTests = await getSplitTests();
    const latestSplit = splitTests && splitTests[0];
    return latestSplit?.id && latestSplit?.active;
  } catch (error) {
    console.error("Unable to fetch site's split test");
    return false;
  }
}

function getExperimentProperties() {
  // Having a nf_ab cookie means the user is in an active split test.
  const nfCookieValue = getCookieValue('nf_ab');

  // Don't send experiment parameters for user outside of a split test
  if (!nfCookieValue) {
    return {};
  }

  return {
    [MIXPANEL_KEYS.NETLIFY_SITE_NAME]: config.netlifySiteName,
    [MIXPANEL_KEYS.EXPERIMENT_BRANCH_NAME]: config.appBranch,
    [MIXPANEL_KEYS.EXPERIMENT_ACTIVATION_VALUE]: nfCookieValue,
  };
}

function forceOptIn(branchName: string) {
  // set the cookie with the name of the branch of our private beta for 1 year
  const now = new Date();
  const expires = now.getTime() + 1000 * 3600 * 24 * 365;
  now.setTime(expires);
  document.cookie = `nf_ab=${branchName}; expires=${now.toUTCString()}`;

  // reload the page to pick up the new option
  // (forcing the browser to re-request it, rather than serving from browser cache)
  window.location.reload(true);
}

function forceOptOut() {
  // clear and expire the cookie.
  const expiresInTime = 'expires=Thu, 01 Jan 1970 00:00:01 GMT';
  document.cookie = `nf_ab=;${expiresInTime}`;

  // reload the page to pick up the new option
  window.location.reload(true);
}

function deleteCookie(cookieName: string, path: string = '/') {
  const expiresInTime = 'expires=Thu, 01 Jan 1970 00:00:01 GMT';
  document.cookie = `${cookieName}=;${expiresInTime};path=${path}`;
}

function setCookieExpiration(
  cookieName: string,
  cookieValue: string,
  endDate: string,
  path: string = '/',
) {
  const utcTime = new Date(`${endDate}T00:01:00-05:00`).toUTCString();
  const expiresInTime = `expires=${utcTime}`;
  document.cookie = `${cookieName}=${cookieValue};${expiresInTime};path=${path}`;
}

export {
  deleteCookie,
  getExperimentProperties,
  forceOptIn,
  forceOptOut,
  getCookieValue,
  isSplitTestActive,
  setCookieExpiration,
};
