import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import { ApiFail } from 'utils/ErrorService';
import { Pagination } from 'models/interfaces/shared/IPagination';
import { ILoadData } from 'models/interfaces/shared/ILoadData';
import { MIXPANEL_EVENTS, MIXPANEL_KEYS } from 'constants/Mixpanel';
import { mixpanelTrack } from 'services/FOMixpanel';
import { toJS } from 'mobx';
import { deadheadBatchCalculation, formatDeadhead, formatYearMonthDay, omit } from 'utils/utility';
import { LoadStatusCode } from 'constants/LoadStatus';
import config from 'config';
import { getTokenHeader } from '../GetTokenHeader';

const searchResultsSample = {
  _id: '9e845067-451e-4818-8c0e-dfda3bdb8e90',
  status: 'PC',
  expire: '2021-12-02T16:00:00',
  loadId: '9e845067-451e-4818-8c0e-dfda3bdb8e90',
  assignedDate: null,
  source: 'convoy',
  companyName: 'convoy',
  pickups: [
    {
      type: 'pickup',
      position: {
        type: 'Point',
        coordinates: [-74.20407449999999, 40.6669059],
      },
    },
  ],
  dropoffs: [
    {
      type: 'dropoff',
      position: {
        type: 'Point',
        coordinates: [-75.3936935, 40.5294058],
      },
    },
  ],
  pickupCity: 'Elizabeth',
  pickupState: 'NJ',
  pickupCountry: 'United States',
  dropoffCity: 'Center Valley',
  dropoffState: 'PA',
  dropoffCountry: 'United States',
  dropoffDate: '2021-12-03T04:30:00',
  payment: {
    amount: 4000,
    perMileRate: 2.34,
    currency: 'USD',
    bookItNow: {
      canBookItNow: true,
    },
  },
  contact: {
    name: 'Bob',
    phone: '+12895276446',
    email: 'bob@broker.com',
    companyName: "Bob's Brokerage",
  },
  weight: 26947,
  ftl: true,
  distance: 82.5733963652271,
  equipmentTypeList: ['reefer'],
  pickupDate: '2021-12-02T16:00:00',
  externalLink:
    'https://carrier.convoy.com/offer/D-9e845067-451e-4818-8c0e-dfda3bdb8e90?ref_source=c3p&ref_code=90370945',
};

const loadObj: ILoadData = {
  loadId: '5ef0eebe6cd998fe7aa05afb',
  shortId: 'fo-fklc',
  status: LoadStatusCode.BOOKED,
  trackingNumber: '',
  account: {},
  brokerId: 'wswDSzqXSqMmuaXwTJnBYAjHuTm1',
  token: '',
  source: 'FleetOps',
  key: '',
  payload: {
    loadPay: {
      amount: 123,
      perMileRate: 0.35,
      currency: 'CAD',
      priceIsEstimated: false,
      range: { high: null, originalHigh: null, low: null, originalLow: null },
      emailUpdates: null,
      originalAmount: null,
    },
    daysUntilPayment: null,
    customerName: null,
    assignedDate: '2020-06-22T17:56:14.989000',
    loadStatus: 'PC',
    tripDetails: {
      pickupStartTime: '01:55 PM',
      pickupEndTime: '01:55 PM',
      dropoffs: [
        {
          dropoffEndDate: '',
          dropoffEndTime: '01:55 PM',
          dropoffStartDate: '2020-06-30T00:00:00',
          cosigneeInfo: {
            address: {
              description: '',
              coordinates: '',
              city: '',
              state: '',
              country: '',
              county: '',
              streetNumber: '',
              streetName: '',
              postalCode: '',
            },
            fullName: '',
            hideReceiverInfo: null,
            freightCharges: null,
          },
          dropoffLocation: '327 Boyd Street, Los Angeles, CA, USA',
          dropoffStartTime: '01:55 PM',
          dropoffCoordinates: { lat: 34.0469671, lng: -118.2433587 },
          receiverInfo: {
            fullName: '',
            address: [
              {
                description: '',
                coordinates: '',
                city: '',
                state: '',
                country: '',
                county: '',
                streetNumber: '',
                streetName: '',
                postalCode: '',
              },
            ],
          },
          dropoffAddress: {
            streetNumber: '327',
            streetName: 'Boyd Street',
            city: 'Los Angeles',
            state: 'California',
            postalCode: '90013',
            country: 'United States',
            county: 'Los Angeles County',
          },
        },
      ],
      pickupLocation: '1 Apple Park Way, Cupertino, CA, USA',
      hideAddressInfo: null,
      pickupCoordinates: { lat: 37.3294281, lng: -122.0102583 },
      pickupStartDate: '2020-06-22T00:00:00',
      pickupEndDate: '',
      pickupAddress: {
        streetNumber: '1',
        streetName: 'Apple Park Way',
        city: 'Cupertino',
        state: 'California',
        postalCode: '95014',
        country: 'United States',
        county: 'Santa Clara County',
      },
    },
    loads: [
      {
        loadContentDetails: {
          weight: { unit: 'lb(s)', amount: 123 },
          equipmentTypes: {
            specialized: {
              animalCarrier: false,
              movingVan: false,
              heavyHaulers: false,
              boatHauler: false,
            },
            container: false,
            tanker: false,
            auto: false,
            reefer: false,
            flatbed: {
              stepDeck: false,
              maxi: false,
              doubleDrop: false,
              lowboy: false,
              removableGooseneck: false,
              flatbed: false,
              landoll: false,
            },
            powerOnly: false,
            van: true,
            hopperBottom: false,
            dumpTruck: false,
          },
          additional: {
            cashOnDelivery: null,
            teamRequired: null,
            mode: { prepaid: null, collect: null },
            customerCheck: null,
          },
          handlingUnits: { type: null, amount: null },
          ftl: true,
          ltl: { nfmcClass: null },
          equipmentSpecifications: {
            hazMat: false,
            palletExchange: false,
            liftGate: false,
            curtains: false,
            airRide: false,
            insulated: false,
            tempControlled: false,
            openTop: false,
            eTrack: false,
            walkingFloor: false,
            hotShot: false,
            conestoga: false,
            extendable: false,
            blanketWrap: false,
            sideKit: false,
            bTrain: false,
            chassis: false,
            chains: false,
            loadRamps: false,
            loadOut: false,
            intermodal: false,
            vented: false,
            tarps: false,
          },
          package: { type: null, quantity: null },
          commodityDescription: {
            hazardousMaterialsCheck: null,
            description: '',
            specialCareInstructions: '',
          },
          dimensions: {
            length: { unit: 'inch', amount: 0 },
            height: { unit: 'inch', amount: 0 },
            width: { unit: 'inch', amount: 0 },
          },
        },
      },
    ],
    brokerName: ['FO Test Broker N'],
    loadName: null,
  },
  postedBy: {
    id: '5b3d2686bdafc7000a609290',
    email: 'test+user1@fleetops.ai',
    phone: '+14541231212',
    emailVerified: true,
    userType: 'broker',
    displayName: 'FO Test Broker N',
    creationTimestamp: 1530734213000,
    disabled: false,
    firebaseUID: 'wswDSzqXSqMmuaXwTJnBYAjHuTm1',
    additionalInfo: { equipmentType: '23', freightType: '23', preferredLane: '23' },
    uploadedFiles: {
      docInterstateCertificate: 'StillUploadingFile',
      liabilityInsuranceDoc:
        'https://s3-us-west-2.amazonaws.com/docs.fleetops.ai/1545060784-truck_and.png',
      docDot: 'https://s3-us-west-2.amazonaws.com/docs.fleetops.ai/1545060799-truck_and.png',
      liabilityInsuranceProviderEmail: '23@1.com',
      liabilityInsuranceProviderName: '23',
    },
    equipmentTypeList: [],
    truckType: '',
    preferredPerMileRate: { price: 0, currency: 'USD' },
    truckCount: 0,
    officePhone: '',
    companyName: 'FleetOps',
    companyLogo:
      'https://fleetops-user-documents.s3.amazonaws.com/5b3d2686bdafc7000a609290/profile/companyLogo.png',
    companyType: 'Owner-Operator',
    address: { line1: '123 Edwayd', line2: '', city: 'Toronto', state: 'MI', postalCode: 'M1H1H1' },
    mcNumber: '123123',
    preferredLanes: { states: ['OR', 'WY'], followMoney: false, skipOnboarding: false },
    agreedToTermsAndConditions: {},
    channel: 'bigroad',
    firstLogin: false,
    tutorial: {
      firstLoadMatch: '2019-07-15T19:56:32.395000',
      searchPage: '2020-05-29T03:32:22.527000',
      myLoadsPage: '2020-06-01T19:31:38.500000',
      settingsPage: '2020-06-01T19:31:43.893000',
      RALPage: '2020-06-10T21:07:45.888000',
    },
    tac: '2019-08-30T15:10:18.005000',
    operatingLanes: [],
    permissions: null,
    dispatchable: 'new',
    lastLogin: '2020-06-22T19:12:07.747000',
    freightType: 'FTL',
    shipmentVolume: { low: 10, high: 20, frequency: 'weekly' },
    industry: 'Construction',
    documents: { w9: '5b3d2686bdafc7000a609290/documents/5a01f0b7028d482ecefa5012bb6b8f07.pdf' },
    communicationsPreferences: {
      load_request_responses: true,
      scientific_recommendations: true,
      brf_certified_recommendations: true,
      contact_email: null,
      email: true,
      push: true,
    },
  },
  interactions: [
    { eventType: 'BO', timestamp: 1592848393.4493945, matchId: '5ef0f0096cd998fe7aa05b00' },
  ],
  matches: [
    {
      matchId: '5ef0f0096cd998fe7aa05b00',
      loadId: '5ef0eebe6cd998fe7aa05afb',
      status: 'BO',
      notifications: {},
      timestamp: 1592848393,
      cost: 0,
      personId: '758',
      interactions: [
        {
          eventType: 'AUTR',
          userType: 'broker',
          firebaseUID: 'wswDSzqXSqMmuaXwTJnBYAjHuTm1',
          timestamp: 1592848575,
          metadata: { mode: 'automated' },
          displayName: 'FO Test Broker N',
          phone: '+14541231212',
          email: 'test+user1@fleetops.ai',
          mcNumber: '123123',
          companyName: 'FleetOps',
        },
        {
          eventType: 'AC',
          userType: 'broker',
          firebaseUID: 'wswDSzqXSqMmuaXwTJnBYAjHuTm1',
          timestamp: 1592848393,
          metadata: {},
          displayName: 'FO Test Broker N',
          phone: '+14541231212',
          email: 'test+user1@fleetops.ai',
          mcNumber: '123123',
          companyName: 'FleetOps',
        },
      ],
      autoMatchId: null,
      uploadedFiles: {},
      active: true,
      accepted: false,
      liked: false,
      sentBy: '',
      truck: {
        _id: '5ba129360fe4dd099ad66088',
        personId: '758',
        email: 'justin@fleetrover.com',
        fleetId: '258',
        firstName: 'Justin',
        lastName: '',
        hosSettings: {},
        roles: ['driver'],
        phone: '',
        freightType: '',
        preferredLane: '',
        equipmentType: '',
        homeCountry: 'US',
        crossBorder: true,
        dotNumber: '2323',
        mcNumber: '123123',
        equipmentTypeList: ['van'],
        preferredPerMileRate: { price: 2.25, currency: 'USD' },
        truckCount: 10,
        officePhone: '+14160000002',
        companyName: 'fleetOps Inc.',
        preferredLanes: { states: [], followMoney: false, skipOnboarding: true },
        dispatchable: 'notDispatchable',
        truckId: '5ba129360fe4dd099ad66088',
        fleet: {
          _id: '5c7813e34a81dd633cdb1848',
          fleetId: '258',
          carrierName: `${config.partner.name} Express`,
          freightDispatcherEnabled: false,
          address: ['123 Fake St', 'Waterloo', 'ON', 'United States', 'N2K4L1'],
          addressLocation: {},
          dotNumber: '2323',
        },
        userType: 'driver',
        lastLogin: '2020-06-22T14:44:26.721000',
        lastLocation: {
          _id: '5ef0c3cb6cd998fe7aa05aa9',
          asOf: '2020-06-22T14:44:27.981000',
          bearing: '',
          hosStatus: 'NA',
          personId: '758',
          position: { lon: '74.12399599999999', lat: '15.299326499999998', accuracy: '32601' },
          speed: '0',
          source: 'web-ip',
        },
      },
      metadata: {},
    },
  ],
};

const { CancelToken } = axios;
let source = CancelToken.source();

export const SearchLoads = async (
  pageNumber,
  { searchQuery, sortFilter = [], currentCoordinates = {} },
) => {
  source && source.cancel('Operation canceled due to new request.');
  source = axios.CancelToken.source();

  if (!searchQuery) {
    throw new Error('Invalid searchQuery provided');
  }

  const { currentUser } = firebase.auth();
  if (!currentUser) {
    throw new Error('No firebaseUID for user');
  }
  const tokenHeader = await getTokenHeader();

  let url = `${config.apiUrlV2}/search/?page=${pageNumber}`;
  sortFilter?.forEach((filter: SortValues) => {
    url = `${url}&sortBy=${filter}`;
  });

  if (searchQuery.limit) {
    url = `${url}&limit=${searchQuery.limit}`;
  } else if (pageNumber == 1) {
    const limit = 30;
    url = `${url}&limit=${limit}`;
  }

  if (searchQuery.personId && searchQuery.personId.length > 0) {
    searchQuery.personId?.forEach((driver: string) => {
      searchQuery.personId = driver;
    });
  } else {
    searchQuery = omit(searchQuery, ['personId']);
  }

  try {
    const response = await axios.post(
      url,
      { ...searchQuery, currentCoordinates },
      {
        headers: tokenHeader,
        cancelToken: source.token,
        retry: 5,
        retryDelay: 1000,
      },
    );

    /**
      Track events for searches done only through regular search flow
      Tracking added after API call to get total items
      */
    const mixpanelProps = {
      [MIXPANEL_KEYS.PICKUP_LOCATION]:
        searchQuery.pickupLocation && searchQuery.pickupLocation.address,
      [MIXPANEL_KEYS.PICKUP_DATE_START]: searchQuery.pickupRange
        ? formatYearMonthDay(searchQuery.pickupRange.range_start, true)
        : null,
      [MIXPANEL_KEYS.PICKUP_DATE_END]: searchQuery.pickupRange?.range_end
        ? formatYearMonthDay(searchQuery.pickupRange.range_end, true)
        : null,
      [MIXPANEL_KEYS.PICKUP_DATE_ENTERED]: searchQuery.pickupRange ? 'Yes' : 'No',
      [MIXPANEL_KEYS.DROPOFF_LOCATION]:
        searchQuery.dropoffLocation && searchQuery.dropoffLocation.address,
      [MIXPANEL_KEYS.DROPOFF_DATE_START]: searchQuery.dropoffRange
        ? formatYearMonthDay(searchQuery.dropoffRange.range_start, true)
        : null,
      [MIXPANEL_KEYS.DROPOFF_DATE_END]: searchQuery.dropoffRange?.range_end
        ? formatYearMonthDay(searchQuery.dropoffRange.range_end, true)
        : null,
      [MIXPANEL_KEYS.EQUIPMENT_LIST]:
        toJS(searchQuery.equipmentTypeList) && toJS(searchQuery.equipmentTypeList).length > 0
          ? toJS(searchQuery.equipmentTypeList)
          : 'All',
      [MIXPANEL_KEYS.DEADHEAD]:
        searchQuery.radius && searchQuery.radius.amount
          ? `${searchQuery.radius.amount} miles`
          : null,
      [MIXPANEL_KEYS.PAGE_NUMBER]: pageNumber,
      [MIXPANEL_KEYS.TOTAL_RESULTS_COUNT]: response?.data?.pagination?.totalItems,
      [MIXPANEL_KEYS.CURRENT_LOCATION_USED]:
        searchQuery.pickupLocation && searchQuery.pickupLocation.isCurrentLocation ? 'Yes' : 'No',
      [MIXPANEL_KEYS.T_CODE]: searchQuery.tCode || '',
    };
    if (pageNumber > 1) {
      mixpanelTrack(MIXPANEL_EVENTS.SEARCH_NEW_PAGE, {
        ...mixpanelProps,
      });
    } else if (searchQuery.isRALSearch) {
      mixpanelTrack(MIXPANEL_EVENTS.RAL_CURRENT_LOADS_VIEWED, {
        ...mixpanelProps,
      });
    } else {
      mixpanelTrack(MIXPANEL_EVENTS.SEARCH, {
        ...mixpanelProps,
      });
    }

    if (response.data && response.data.data) {
      let deadheadResults = [];
      // Calculate deadhead sync for 'load more'
      // Do not calculate deadhead for state level search
      if (response?.data?.pagination?.page !== 1 && searchQuery.pickupLocation.city) {
        deadheadResults = await deadheadBatchCalculation(
          response.data.data,
          searchQuery.pickupLocation,
        );
      }
      return {
        data: response.data.data.map((load, i) => {
          // Used as key while mapping over loads in the UI in case loadId dupes exist
          load.feUniqueKey = Math.random();

          /**
           * Load doesn't have `loadId` property when coming from partially detailed API call
           * But it has `_id` property, which is the same, but only renamed
           * (made for search results speed optimization on BE)
           */
          if (!load?.loadId && load?._id) {
            load.loadId = load._id;
          }
          if (deadheadResults.length > 0) {
            if (!sortFilter.includes('deadhead')) {
              load.deadhead = formatDeadhead(deadheadResults[i]);
            } else {
              load.deadhead += ' mi';
            }
          }
          return load;
        }),
        pagination: response.data.pagination,
      };
    }
    return {
      data: [],
      brokers: [],
      pagination: new Pagination(),
    };
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled', error.message);
      return {
        data: [],
        pagination: new Pagination(),
      };
    }
    ApiFail(error);
    throw error;
  }
};
