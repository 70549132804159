import BRFLogo from 'assets/images/logos/BRF-logo.svg';
import production from './production';
import staging from './staging';
import development from './development';
import test from './test';

const configVariants = {
  production,
  staging,
  development,
  test,
};
const config = {
  freshworksId: 43000001978,
  partner: {
    url: 'https://app.bigroadfreight.com',
    name: 'BigRoad Freight',
    partnerName: 'BigRoad',
    logo: BRFLogo,
  },
  ...configVariants[process.env.NODE_ENV || 'development'],
};

export default config;
